<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <h5 class="m-4">Clientes</h5>
          </template>
          <template v-slot:end>
            <div class="grid p-fluid">
              <div class="col-2 md:col-2">
                <div class="field">
                  <label for="identificacion_cliente" class="mb-2"
                    >Identificación cliente</label
                  >
                  <InputText
                    type="text"
                    id="identificacion_cliente"
                    name="identificacion_cliente"
                    placeholder="Buscar"
                    v-model="filtrarPedido.identificacion_cliente"
                  ></InputText>
                </div>
              </div>
              <div class="col-2 md:col-2">
                <div class="field">
                  <label for="celular" class="mb-2">Celular</label>
                  <InputText
                    type="text"
                    id="celular"
                    name="celular"
                    placeholder="Buscar"
                    v-model="filtrarPedido.celular"
                  ></InputText>
                </div>
              </div>
              <div class="col-2 md:col-2">
                <div class="field">
                  <label for="email" class="mb-2">Correo</label>
                  <InputText
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Buscar"
                    v-model="filtrarPedido.email"
                  ></InputText>
                </div>
              </div>
              <div class="col-2 md:col-2">
                <div class="field">
                  <label for="publicado" class="mr-2 mb-2"
                    >Estado de pedido</label
                  >
                  <Dropdown
                    v-model="filtrarPedido.estado"
                    :options="estados"
                    @complete="search"
                    :suggestions="items"
                    optionLabel="nombre"
                    optionValue="id"
                    placeholder="Seleccionar..."
                    :showClear="true"
                  />
                </div>
              </div>
              <div class="flex-custom col-4">
                <div></div>
                <div class="flex">
                  <Button
                    label="Buscar"
                    icon="pi pi-search"
                    class="p-button-primary w-full mr-2 h-2"
                    @click="filtrar()"
                  />
                  <Button
                    id="crearTrasnportadora"
                    label="Crear"
                    icon="pi pi-plus"
                    class="p-button-success w-full mr-2"
                    @click="nuevaPolitica()"
                  />
                  <Button
                    icon="pi pi-external-link"
                    label="Exportar"
                    @click="exportCSV($event)"
                    class="w-full mr-2"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="mr-3 flex flex-column">
              <label for="identificacion_cliente" class="mb-2"
                >Identificación cliente</label
              >
              <InputText
                type="text"
                id="identificacion_cliente"
                name="identificacion_cliente"
                placeholder="Buscar"
                v-model="filtrarPedido.identificacion_cliente"
              ></InputText>
            </div>
            <div class="mr-3 flex flex-column">
              <label for="celular" class="mb-2">Celular</label>
              <InputText
                type="text"
                id="celular"
                name="celular"
                placeholder="Buscar"
                v-model="filtrarPedido.celular"
              ></InputText>
            </div>
            <div class="mr-3 flex flex-column">
              <label for="email" class="mb-2">Correo</label>
              <InputText
                type="text"
                id="email"
                name="email"
                placeholder="Buscar"
                v-model="filtrarPedido.email"
              ></InputText>
            </div>
            <div class="mr-3 flex flex-column">
              <label for="publicado" class="mr-2 mb-2">Estado de pedido</label>
              <Dropdown
                v-model="filtrarPedido.estado"
                :options="estados"
                @complete="search"
                :suggestions="items"
                optionLabel="nombre"
                optionValue="id"
                placeholder="Seleccionar..."
                :showClear="true"
              />
            </div>
            <div class="flex">
              <Button
                label="Buscar"
                icon="pi pi-search"
                class="p-button-primary w-full mr-2 h-2"
                @click="filtrar()"
              />
              <Button
                id="crearTrasnportadora"
                label="Crear cliente"
                icon="pi pi-plus"
                class="p-button-success w-full mr-2"
                @click="nuevaPolitica()"
              />
              <Button
                icon="pi pi-external-link"
                label="Exportar"
                @click="exportCSV($event)"
                class="w-full mr-2"
              />
            </div> -->
          </template>
        </Toolbar>
        <!-- Inicio tabla listado de cliente -->
        <DataTable
          ref="dt"
          :value="clientes"
          stripedRows
          v-model:selection="politicaSeleccionada"
          dataKey="id"
          :paginator="false"
          :rows="20"
          :filters="filters"
          :loading="loading"
          pagingType="numbers"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>
          <Column selectionMode="multiple"></Column>
          <!-- Nombre de la columna cliente -->
          <Column
            field="nombre"
            header="Nombre"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              {{ slotProps.data.nombre }}
            </template>
          </Column>
          <Column
            field="apellido"
            header="Apellido"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              {{ slotProps.data.apellido }}
            </template>
          </Column>
          <Column
            field="numero_identificacion"
            header="Numero Identificacion"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              {{ slotProps.data.numero_identificacion }}
            </template>
          </Column>
          <Column
            field="celular"
            header="Celular"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              {{ slotProps.data.celular }}
            </template>
          </Column>
          <Column
            field="email"
            header="Correo Electronico"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column
            field="pais"
            header="Pais"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              {{ slotProps.data.pais }}
            </template>
          </Column>
          <Column
            field="departamento"
            header="Departamento"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              {{ slotProps.data.departamento }}
            </template>
          </Column>
          <Column
            field="ciudad"
            header="Ciudad"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              {{ slotProps.data.ciudad }}
            </template>
          </Column>
          <Column
            field="politicas"
            header="Politicas"
            :sortable="true"
            class="cursor-pointer"
          >
            <template #body="slotProps">
              <div
                v-for="p in JSON.parse(slotProps.data.politicas)"
                :key="p.key"
              >
                {{ p.id_politica }}
                {{ p.aceptada ? "Aceptada" : "No aceptada" }}
                {{ p.fecha_aceptacion }}
              </div>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editarPolitica(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <Paginator
          :rows="1"
          :totalRecords="totalRecord"
          @page="onPage($event)"
        ></Paginator>
        <!-- Fin tabla listado de clientes -->

        <!-- Inicio modal formulario crear o editar clientes -->
        <Dialog
          v-model:visible="politicaDialog"
          :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
          :style="{ width: '60vw' }"
          :header="cliente.nombre ? cliente.nombre : 'Crear cliente'"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 xl:col-4">
              <div class="field">
                <label for="nombre">Nombre*</label>
                <InputText
                  id="nombre"
                  name="nombre"
                  v-model.trim="cliente.nombre"
                  required="true"
                  type="text"
                  spellcheck="false"
                  autofocus
                  :class="{
                    'p-invalid': noEnviar && v$.cliente.nombre.$invalid,
                  }"
                />
                <small
                  class="p-error"
                  v-if="noEnviar && v$.cliente.nombre.$invalid"
                >
                  El campo nombre es requerido.
                </small>
              </div>
            </div>

            <div class="col-12 xl:col-4">
              <div class="field">
                <label for="apellido">Apellido*</label>
                <InputText
                  id="apellido"
                  name="apellido"
                  v-model.trim="cliente.apellido"
                  required="true"
                  type="text"
                  spellcheck="false"
                  :class="{
                    'p-invalid': noEnviar && v$.cliente.apellido.$invalid,
                  }"
                />
                <small
                  class="p-error"
                  v-if="noEnviar && v$.cliente.apellido.$invalid"
                >
                  El campo apellido es requerido.
                </small>
              </div>
            </div>

            <div class="col-12 xl:col-4">
              <div class="field">
                <label for="numero_identificacion"
                  >Numero Identificacion *</label
                >
                <InputText
                  id="numero_identificacion"
                  name="numero_identificacion"
                  v-model.trim="cliente.numero_identificacion"
                  disabled
                />
              </div>
            </div>

            <div class="col-12 xl:col-4">
              <div class="field">
                <label for="celular">Celular</label>
                <span class="p-input-icon-left">
                  <i class="pi pi-mobile" />
                  <InputText
                    type="text"
                    v-model="cliente.celular"
                    placeholder="mobile"
                  />
                </span>
              </div>
            </div>
            <div class="col-12 xl:col-4">
              <div class="field">
                <label for="email">Correo Electronico*</label>
                <span class="p-input-icon-left">
                  <i class="pi pi-send" />
                  <InputText
                    type="text"
                    v-model="cliente.email"
                    placeholder="mobile"
                  />
                </span>
              </div>
            </div>
            <div class="col-12 xl:col-4">
              <div class="field">
                <label for="fecha_creacion">Fecha de creación</label>
                <Calendar
                  inputId="fecha_nacimiento"
                  v-model="fechaFormateada"
                  :showIcon="true"
                  dateFormat="mm-dd-yy"
                  :max-date="fecha_actual"
                  disabled
                />
              </div>
            </div>

            <!-- <div class="field col-12 md:col-4">
              <div class="field">
                <label for="pais">Pais</label>

                <Dropdown
                  id="pais"
                  v-model="cliente.pais"
                  :options="cities"
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select a City"
                />
              </div>
            </div> -->
            <!-- <div class="col-12 md:col-6 xl:col-4">
              <div class="field">
                <label for="habilitado">¿Habilitado?</label>
                <ToggleButton
                  v-model="cliente.habilitado"
                  onLabel="Si"
                  offLabel="NO"
                  onIcon="pi pi-check"
                  offIcon="pi pi-times"
                />
              </div>
            </div> -->
          </div>

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="ocultarDialog()"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="guardarPolitica(!v$.$invalid)"
            />
          </template>
        </Dialog>
        <!-- Fin formulario crear o editar clientes -->
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { FilterMatchMode } from "primevue/api";
import {
  obtenerTodo,
  crear,
  actualizar,
  filtrar,
} from "@/api/clienteHttpService";
import { decrypt } from "@/auth-guard";

export default {
  name: "clientes",
  setup() {
    return { v$: useVuelidate() };
  },
  data: () => ({
    clientes: [],
    totalRecord: 0,
    fecha_actual: new Date(),
    loading: true,
    cargando: false,
    modalPolitas: false,
    tipos_identicacion: [],
    estadoSeleccionado: null,
    filtrarPedido: {
      identificacion_cliente: null,
      celular: null,
      email: null,
      estado: null,
    },
    cliente: {
      id_user_log: decrypt(localStorage.getItem("id")),
    },
    clientejson: {},
    politicaDialog: false,
    politicaSeleccionada: null,
    filters: {},
    noEnviar: false,
    estados: null,
    cities: [
      { name: "New York", code: "NY" },
      { name: "Rome", code: "RM" },
      { name: "London", code: "LDN" },
      { name: "Istanbul", code: "IST" },
      { name: "Paris", code: "PRS" },
    ],
  }),
  validations() {
    return {
      cliente: {
        nombre: { required },
        apellido: { required },
        numero_identificacion: { required },
      },
    };
  },

  async created() {
    this.initFilters();
    this.obtenerClientes();
    this.obtenerTiposIdentificacion();
    this.obtenerEstado();
  },

  methods: {
    nuevaPolitica() {
      this.politicaDialog = true;
      this.cliente = {};
      this.cliente.id_user_log = decrypt(localStorage.getItem("id"));
    },
    async onPage(event) {
      await this.obtenerClientes(event.page);
    },
    ocultarDialog() {
      this.politicaDialog = false;
    },
    async obtenerEstado() {
      const est = await obtenerTodo("/estados");
      this.estados = est;
    },
    async obtenerClientes(paginado = 0) {
      this.loading = true;
      // let estadoParametro =
      //   this.estadoSeleccionado !== null
      //     ? "&estado=" + this.estadoSeleccionado
      //     : "";

      const diferenteNull = Object.entries(this.filtrarPedido).reduce(
        (acc, [key, value]) => {
          if (value !== null) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      console.log(diferenteNull);
      const parametros = Object.entries(diferenteNull)
        .filter(([value]) => value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");
      let url = `/clientes/clientes/backoffice?paginado=${paginado}&${parametros}`;
      let resp = await obtenerTodo(url);
      this.clientes = resp.clientes;
      this.totalRecord = resp.paginas;
      this.loading = false;
    },

    /* Obtine todos los tipos de identificacion del cliente */
    async obtenerTiposIdentificacion() {
      const tipoId = await filtrar("/clientes/tipos_identificacion");
      this.tipos_identicacion = tipoId;
    },
    async filtrar() {
      this.obtenerClientes();
    },
    async guardarPolitica(isFormValid) {
      if (isFormValid) {
        this.noEnviar = false;
        if (this.cliente.id) {
          //Actualiza
          const actualiza = await actualizar("/clientes", this.cliente);
          console.log(actualiza);
          if (actualiza === "OK") {
            this.cliente = {};
            this.politicaDialog = false;
            this.obtenerPoliticas();
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: "Los datos de la Cliente fueron actualizados",
              life: 3000,
            });
          }
        } else {
          //Verifica que la cliente no exista.
          if (
            this.clientes.find(
              (cliente) =>
                cliente.nombre === this.cliente.nombre &&
                cliente.numero_identificacion ===
                  this.cliente.numero_identificacion
            )
          ) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: `La Cliente ${this.cliente.nombre} ya existe`,
              life: 5000,
            });
            return;
          }
          //Crea
          this.loading = true;
          const crea = await crear("clientes/registrarCliente", this.cliente);
          if (crea === "Created") {
            this.cliente = {};
            this.politicaDialog = false;
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: "Cliente Creada",
              life: 3000,
            });
            this.obtenerPoliticas();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Ha ocurrido un error, no se pudo crear la cliente ❌",
              life: 3000,
            });
          }
          this.loading = false;
        }
      } else {
        this.noEnviar = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Porfavor verifique los campos",
          life: 3000,
        });
      }
    },
    editarPolitica(cliente) {
      this.cliente = { ...cliente };
      this.politicaDialog = true;
      this.cliente.id_user_log = decrypt(localStorage.getItem("id"));
    },
    exportCSV() {
      this.loading = true;
      console.log(decrypt(localStorage.getItem("token")).token);
      let estadoParametro =
        this.estadoSeleccionado !== null
          ? "&estado=" + this.estadoSeleccionado
          : "";
      location.href = `${
        process.env.VUE_APP_BASE_URL_BACKEND
      }export/clientes?token=${
        decrypt(localStorage.getItem("token")).token
      }${estadoParametro}`;
      this.loading = false;
      this.$toast.add({
        severity: "success",
        summary: "Exportación exitosa",
        detail: "Se ha exportado el archivo correctamente",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  computed: {
    fechaFormateada() {
      if (this.cliente.created_at) {
        return new Date(this.cliente.created_at).toISOString().split('T')[0];
      }
      return null;
    },
    politicas() {
      return JSON.parse(JSON.stringify(this.clientejson));
    },
  },
};
</script>

<style scoped>
.flex-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
